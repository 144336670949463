<template>
    <div class="col-sm-4 col-xs-12">
        <v-card style="border-radius: 10px; height: 200px" elevation="0" class="mx-auto text-center mx-10 mx-auto col-12 text-center" color="white">
            <div style="font-size: 15px; font-weight: 800; height: 20px; margin-top: 12px">
                {{title}}
            </div>
            <apexchart style="border-radius: 10px;" type="area" height="170px" :options="optionsChart" :series="seriesChart"></apexchart>
        </v-card>
    </div>
</template>

<script>
export default {
  name: 'LineChartCard',
  data () {
    return {
    };
  },
  props: ['title', 'optionsChart', 'seriesChart']
};
</script>
