<template>
<v-app>
    <v-row style="height: 100vh; background: red; margin: 0px;">
        <v-navigation-drawer class="mx-auto justify-center" floating v-model="drawer" :mini-variant.sync="mini" permanent style="padding-right: 0px !important; margin-left: 0px; width: 100px;">
            <v-img style="margin: auto; margin-top: 30px;" position='center center' class="mx-auto text-center" width="56" src="augment_logo.png"></v-img>
            <v-list style="margin-top: 30px" dense rounded>
                <v-list-item v-for="item in navbarItems" :key="item.title" v-bind:class="{ active: currentLocation === item.text }" @click="redirectTo(item.text)">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-icon v-bind="attrs" v-on="on" style="margin: auto">
                                <v-icon v-bind:class="{ activeicon: currentLocation === item.text }">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                        </template>
                        <span>{{item.text}}</span>
                    </v-tooltip>
                </v-list-item>
                <v-list-item>
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-icon v-bind="attrs" v-on="on" @click="logout()" style="margin: auto">
                                <v-icon>{{ 'mdi-logout-variant' }}</v-icon>
                            </v-list-item-icon>
                        </template>
                        <span>Logout</span>
                    </v-tooltip>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

    </v-row>
</v-app>
</template>

<style scoped>
.active {
    background: lightcoral;
}

.activeicon {
    color: white !important;
}
</style>

<script>
import {
  mapState,
  mapActions
} from 'vuex';

import router from '../../router';

export default {
  watch: {},
  name: 'SideBar',

  data () {
    return {
      drawer: true,
      items: null,
      mini: true
    };
  },
  methods: {
    redirectTo: function (name) {
      this.$store.state.userData.currentLocation = name;
      switch (name) {
        case 'dashboard':
          router.push('free-dashboard');
          // router.push(!this.user.organization || !this.user.organization.has_analytics_access ? 'free-dashboard' : 'paying-dashboard')
          break;
        case 'billing':
          router.push('billing');
          break;
        case 'home':
          router.push('/');
          break;
      }
    },
    ...mapActions('authenticate', [
      'logout'
    ])
  },
  computed: {
    ...mapState({
      user: state => state.userData.user,
      currentLocation: state => state.userData.currentLocation,
      navbarItems: state => state.globalData.navbarItems
    })
  }
};
</script>
