<template>
<div style="background: rgb(245, 245, 245); margin: 0px !important; width: calc(100vw - 100px); padding: 45px;">
    <h2 class="ml-15" style="font-weight: 300;">Augment Analytics Dashboard</h2>
</div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex';
export default {
  props: {},
  name: 'Home',
  data () {
    return {};
  },
  computed: {
    ...mapState({
      user: state => state.userData.user
    })
  },
  methods: {
    ...mapActions('userData', [
      'getUserData'
    ])
  },
  mounted () {
    if (!this.user) {
      this.getUserData({
        token: this.$route.query.token,
        refresh_token: this.$route.query.refresh_token
      });
    }
  }
};
</script>
