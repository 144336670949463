<template>
    <v-container class="mt-15">
        <v-col md="4" class="mx-auto">
            <v-card elevation="2">
                <v-card-title class='pa-7'>Sign In</v-card-title>
                <v-card-text>
                    <v-col cols="12" sm="12">
                        <v-text-field id="mail-input" v-model="email" :rules="[mail_rules.required, mail_rules.email]" label="E-mail"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-text-field id="password-input" v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[password_rules.required, password_rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 6 characters" counter @click:append="show1 = !show1"></v-text-field>
                    </v-col>
                </v-card-text>

                <v-card-actions class='pa-7'>
                    <v-spacer></v-spacer>
                    <v-btn>Back</v-btn>
                    <v-btn id='login-button' :disabled="(mail_rules.email(email) !== true || password_rules.min(password) !== true)" @click="login({email: email, password: password, model_id: $route.params.uid})">Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-container>
</template>
<style scoped>
    .v-card__actions>.v-btn.v-btn {
        color: rgba(0,0,0,1) !important;
    }
</style>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Auth',
  data () {
    return {
      email: '',
      mail_rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        }
      },
      password_rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 6 || 'Min 6 characters'
      },
      show1: false,
      show2: true,
      show3: false,
      show4: false,
      password: ''
    };
  },
  methods: {
    ...mapActions('authenticate', [
      'login'
    ]),
    loginWithKey: function (event) {
      if (event.keyCode === 13 && !(this.mail_rules.email(this.email) !== true || this.password_rules.min(this.password) !== true)) {
        this.login({ email: this.email, password: this.password, model_id: this.$route.params.uid });
      }
    }
  },
  created () {
    document.addEventListener('keydown', this.loginWithKey, false);
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.loginWithKey, false);
  }
};
</script>
