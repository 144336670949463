<template>
<div style="background: rgb(245, 245, 245); margin: 0px !important;" class="pa-sm-10 pa-xs-0 pt-xs-10 col-xs-12  col-sm-11 mx-auto">
    <h2 class="ml-2" style="font-weight: 300;">Billing Informations <v-chip small color="primary" style="font-weight: 700; font-size: 10px">FREE</v-chip>
    </h2>
     <div v-if="invoiceDropdown === null" class="col-12 text-center mt-10 pt-10">
        <v-progress-circular
            style="margin-top: 25vh"
            :size="50"
            :width="3"
            indeterminate
            color="#21918c"
        ></v-progress-circular>
    </div>

    <div v-if="invoiceDropdown">
        <v-row class="mx-auto col-12">
            <v-row class="mx-auto col-sm-6 col-xs-12">
                <v-row class="ml-0 col-12 mt-5">

                    <v-select class="col-sm-7 col-xs-12" item-text="date" item-value="invoice" v-model="selectedInvoice" :items="invoiceDropdown" label="select billing" dense outlined></v-select>

                    <v-btn :link="true" :href="currentPdfLink" elevation="0" style="height: 40px" color="blue" dense class="white--text ml-2 col-sm-4 col-xs-12">
                        Download PDF
                        <v-icon right dark>
                            mdi-download
                        </v-icon>
                    </v-btn>

                </v-row>
                <NextBillingCompany v-if="!subscriptionAcademic"></NextBillingCompany>
            </v-row>
            <v-row class="mx-auto col-sm-6 col-xs-12">
                <v-card style="border-radius: 20px; width: 100%" elevation="0" class="text-center mx-5 mx-auto pt-5" color="white">
                    <apexchart style="border-radius: 10px;" type="bar" height="275" :options="billing_data_options" :series="billing_data_series"></apexchart>
                </v-card>
            </v-row>
        </v-row>
        <v-row class="mx-auto col-12" v-if="multipleSeries[1].data.length > 1" >
            <v-row class="mx-auto col-12">
                <v-card style="border-radius: 20px;  width: 100%" elevation="0" class="text-center mx-5 mx-auto pt-5" color="white">
                    <apexchart type="line" height="300" :options="multipleChartOptions" :series="multipleSeries"></apexchart>
                </v-card>
            </v-row>
        </v-row>
    </div>
</div>
</template>

<style>
body {
    font-family: 'Roboto';
}

.v-sheet--offset {
    top: -24px;
    position: relative;
}

.grid {
    stroke: black;
    stroke-dasharray: 0;
    stroke-width: 1;
}

.labels {
    font-size: 13px;
}

.label-title {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    fill: black;
}

.apexcharts-tooltip {
    display: flex !important;
}
</style>>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import NextBillingCompany from './billing/NextBillingCompany.vue';

export default {
  created () {},
  watch: {},
  name: 'FreeDashBoard',
  components: {
    NextBillingCompany
  },
  data () {
    return {
      value: 'test',
      chartOptionsTeams: {
        chart: {
          type: 'bar',
          height: 350
          // sparkline: {
          //     enabled: true,
          // }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
        },
        yaxis: {
          title: {
            text: '$ (thousands)'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '$ ' + val + ' thousands';
            }
          }
        }
      },
      seriesUsage: [44, 55, 41, 17, 15],
      chartOptionsUsage: {
        chart: {
          type: 'donut'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }

    };
  },
  methods: {
    ...mapActions('authenticate', [
      'signInRedirect'
    ]),
    ...mapActions('userData', [
      'getBillingData',
      'setBillingData'
    ])
  },

  computed: {
    ...mapState({
      billing_data_series: state => state.userData.billing_data_series,
      billing_data_options: state => state.userData.billing_data_options,
      billing_data_area_count: state => state.userData.billing_data_area_count,
      billing_data_area_series: state => state.userData.billing_data_area_series,
      billing_data_area_options: state => state.userData.billing_data_area_options,
      billing_invoice_variwide_data: state => state.userData.billing_invoice_variwide_data,
      billing_invoice_variwide_options: state => state.userData.billing_invoice_variwide_options,
      multipleSeries: state => state.userData.multipleSeries,
      multipleChartOptions: state => state.userData.multipleChartOptions,
      allReadyPaid: state => state.userData.allReadyPaid,
      prevision: state => state.userData.prevision,
      currentMonthAdd: state => state.userData.currentMonthAdd,
      billing_data_count: state => state.userData.billing_data_count,
      subscriptionAcademic: state => state.userData.subscriptionAcademic,
      invoiceDropdown: state => state.userData.invoiceDropdown,
      currentPdfLink: state => state.userData.currentPdfLink
    }),
    selectedInvoice: {
      get: function () {
        return this.$store.state.userData.selectedInvoice;
      },
      set: function (val) {
        this.setBillingData(val);
        this.$store.state.userData.selectedInvoice = val;
      }
    }
  },
  mounted () {
    this.getBillingData({
      token: this.$route.query.token,
      refresh_token: this.$route.query.refresh_token
    });
  }
};
</script>

 <!-- <v-row class="mx-auto col-12" style="margin-top: 40px">
            <svg id="svgChart" style="background: white; border-radius: 20px; width:100%;" height="300">
                <rect v-for="item in billing_invoice_variwide_data" :key="item.text" x="50" :y="item.y + 50" :height="item.calcHeight" :width="item.width" :transform="item.transform" style="fill:red;stroke:black;stroke-width:1;opacity:0.7">
                </rect>
                <g class="grid y-grid" id="yGrid">
                    <line x1="50" x2="1250" y1="255" y2="255"></line>

                    <line v-for="item in billing_invoice_variwide_data" :key="item.text" :x1="item.x_position + 50" :x2="item.x_position + 50" y1="255" y2="260"></line>

                    <g v-for="item in billing_invoice_variwide_data" :key="item.text" :transform="'translate('+(item.x_position + 50)+', 270)'">
                        <text
                            font-weight="lighter"
                            style="font-size: 10px;"
                            text-anchor="end"
                            transform="rotate(-45)"
                        >
                            {{item.date}}
                        </text>
                    </g>

                    <g transform="translate(1249, 270)">
                        <text
                            font-weight="lighter"
                            style="font-size: 10px;"
                            text-anchor="end"
                            transform="rotate(-45)"
                        >
                            {{billing_invoice_variwide_options.end_date}}
                        </text>
                    </g>

                    <line x1="1249" x2="1249" y1="255" y2="260"></line>
                </g>
                <g class="grid x-grid" id="xGrid">
                    <line x1="40" x2="45" y1="49" y2="49"></line>
                    <line x1="40" x2="45" y1="100" y2="100"></line>
                    <line x1="40" x2="45" y1="150" y2="150"></line>
                    <line x1="40" x2="45" y1="200" y2="200"></line>
                    <line x1="40" x2="45" y1="250" y2="250"></line>

                    <line x1="45" x2="45" y1="50" y2="250"></line>
                </g>
            </svg>
        </v-row> -->
