<template>
    <div class="col-sm-4 col-xs-12">
        <v-card style="border-radius: 10px;" elevation="0" class="d-flex align-center mx-10 mx-auto col-12" color="white">
            <div class="mx-auto align-center pt-3" style="font-size: 15px; font-weight: 800; height: 50px;">
                {{cardText}}
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
  name: 'TextCard',
  data () {
    return {
    };
  },
  props: ['cardText']
};
</script>
