import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/views/Home.vue';
import FreeDashboard from '../components/views/FreeDashboard.vue';
import Billing from '../components/views/Billing.vue';
import Auth from '../components/Auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomeRoot',
    component: Home
  }, {
    path: '/home',
    name: 'Home',
    component: Home
  }, {
    path: '/free-dashboard',
    name: 'FreeDashboard',
    component: FreeDashboard
  }, {
    path: '/billing',
    name: 'Billing',
    component: Billing
  }, {
    path: '/sign-in',
    name: 'Auth',
    component: Auth
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
