<template>
<div class="col-12">
    <table class="col-12">
        <tr>
            <th>name</th>
            <th>value</th>
        </tr>
        <tr>
            <td>Credit</td>
            <td>{{setCurrency(tableCredit)}}</td>
        </tr>
        <tr>
            <td>Current month paid provision</td>
            <td>{{setCurrency(tableCurrentPaidProvision)}}</td>
        </tr>
        <tr>
            <td>Current month real charges</td>
            <td>{{setCurrency(tableCurrentPaidCharges)}}</td>
        </tr>
        <tr>
            <td>Next month provision</td>
            <td>{{setCurrency(tableNextProvisions)}}</td>
        </tr>
        <tr>
            <td>Applied balance</td>
            <td>{{setCurrency(tableAppliedBalance)}}</td>
        </tr>
        <tr>
            <td>Amount due</td>
            <td>{{setCurrency(billing_data_count)}}</td>
        </tr>
    </table>
</div>
</template>

<style scoped>
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding-left: 2px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}
</style>

<script>
import {
  mapState
} from 'vuex';

export default {
  created () {},
  watch: {},
  name: 'NextBillingCompany',
  data () {
    return {};
  },
  computed: {
    ...mapState({
      billing_data_series: state => state.userData.billing_data_series,
      billing_data_options: state => state.userData.billing_data_options,
      billing_data_area_count: state => state.userData.billing_data_area_count,
      billing_data_area_series: state => state.userData.billing_data_area_series,
      billing_data_area_options: state => state.userData.billing_data_area_options,
      billing_invoice_variwide_data: state => state.userData.billing_invoice_variwide_data,
      billing_invoice_variwide_options: state => state.userData.billing_invoice_variwide_options,
      multipleSeries: state => state.userData.multipleSeries,
      multipleChartOptions: state => state.userData.multipleChartOptions,
      allReadyPaid: state => state.userData.allReadyPaid,
      prevision: state => state.userData.prevision,
      currentMonthAdd: state => state.userData.currentMonthAdd,
      billing_data_count: state => state.userData.billing_data_count,
      subscriptionAcademic: state => state.userData.subscriptionAcademic,
      tableCredit: state => state.userData.tableCredit,
      tableCurrentPaidProvision: state => state.userData.tableCurrentPaidProvision,
      tableCurrentPaidCharges: state => state.userData.tableCurrentPaidCharges,
      tableNextProvisions: state => state.userData.tableNextProvisions,
      tableAppliedBalance: state => state.userData.tableAppliedBalance,
      tableAmountDue: state => state.userData.tableAmountDue,
      currency: state => state.userData.currency
    })
  },
  methods: {
    setCurrency (val) {
      return this.currency === 'eur' ? val + '€' : '$' + val;
    }
  }
};
</script>
