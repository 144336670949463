import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import 'es6-promise/auto';
import axios from 'axios';
import authenticate from './modules/authenticate';
import userData from './modules/userData';
import freeDashboardData from './modules/freeDashboardData';
import globalData from './modules/globalData';
import { VueAuthenticate } from 'vue-authenticate';
import router from '../router';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_HYPERTEXT + '://webservice.' + process.env.VUE_APP_RAILS_URL,
  loginUrl: '/v3/oauth/token',
  tokenUrl: '/v3/oauth/token'
});

export default new Vuex.Store({
  state: {
    vueAuth: vueAuth,
    rooter: router
  },
  modules: {
    authenticate,
    userData,
    freeDashboardData,
    globalData
  }
});
