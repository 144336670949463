<template>
<v-app>
    <div :class="{ row: windowWidth > 600 }" style="height: 100vh; background: rgb(245, 245, 245); margin: 0px;">
        <SideBar v-if="windowWidth > 600"></SideBar>
        <TopMobileBar v-if="windowWidth <= 600"></TopMobileBar>
        <router-view></router-view>
        <v-snackbar v-for="(snackbar, index) in snackbars.filter(s => s.showing)" :key="snackbar.text + Math.random()" v-model="snackbar.showing" :timeout="snackbar.timeout" :color="snackbar.color" :style="`bottom: ${(index * 60) + 8}px`" bottom right transition="fade-transition">
          <div v-html="snackbar.text"></div>
        </v-snackbar>
    </div>
</v-app>
</template>

<style>
body {
    font-family: 'Roboto';
}

.v-sheet--offset {
    top: -24px;
    position: relative;
}
</style>

<script>
import SideBar from './components/layout/SideBar.vue';
import TopMobileBar from './components/layout/TopMobileBar.vue';

import { mapActions, mapState } from 'vuex';

export default {
  watch: {},
  name: 'App',
  components: {
    SideBar,
    TopMobileBar
  },
  data () {
    return {
      windowWidth: null
    };
  },
  computed: {
    ...mapState({
      snackbars: state => state.globalData.snackbars
    })
  },
  methods: {
    ...mapActions('authenticate', [
      'signInRedirect'
    ])
  },
  mounted () {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
};
</script>
