import router from './../../router';
import axios from 'axios';

const state = () => ({
  isAuthenticated: false,
  token: null
});

// getters
const getters = {};

// actions
const actions = {
  login ({ commit }, payload) {
    var vueAuth = this.state.vueAuth;
    var actions = this._actions;
    var mutations = this._mutations;
    var navbarItems = this.state.globalData.navbarItems;
    vueAuth.login({ username: payload.email, password: payload.password, grant_type: 'password', client_id: process.env.VUE_APP_CLIENT_ID, client_secret: process.env.VUE_APP_CLIENT_SECRET }).then((response) => {
      commit('setToken', {
        token: response.data.access_token
      });
      vueAuth.storage.setItem('refresh_token', response.data.refresh_token);
      commit('isAuthenticated', {
        isAuthenticated: vueAuth.isAuthenticated()
      });
      if (vueAuth.isAuthenticated() === true) {
        axios.get(process.env.VUE_APP_HYPERTEXT + '://webservice.' + process.env.VUE_APP_RAILS_URL + '/v3/users/infos')
          .then(function (response) {
            mutations['userData/setUser'][0](response.data);
            navbarItems = [];
            if (response.data.has_enterprise_plan) {
              actions['globalData/addBilling'][0]();
            }
            if (response.data.master_user) {
              actions['globalData/addDashboard'][0]();
              axios.get(process.env.VUE_APP_HYPERTEXT + '://webservice.' + process.env.VUE_APP_RAILS_URL + '/v3/keen_api_keys/get_key')
                .then(function (response) {
                  mutations['userData/setKeenKey'][0](response.data);
                });
            }
            router.push('/');
          });
      } else {
        actions['globalData/addSnackbar'][0]({ text: 'login error', showing: true, color: 'error', timeout: 2000 });
      }
    }).catch(err => {
      console.log(err);
      actions['globalData/addSnackbar'][0]({ text: 'Your email - password combination is invalid', showing: true, color: 'error', timeout: 2000 });
    });
  },
  logout () {
    var vueAuth = this.state.vueAuth;
    var mutations = this._mutations;
    const query = Object.assign({}, router.query);
    delete query.my_param;
    vueAuth.logout({
      makeRequest: true,
      redirect: { name: 'auth-login' }
    }).then((response) => {
      vueAuth.storage.removeItem(vueAuth.tokenName);
      vueAuth.storage.removeItem('refresh_token');
      mutations['userData/setUser'][0](null);
      router.replace({ query });
      router.go();
      actions['globalData/addSnackbar'][0]({ text: 'logged out', showing: true, color: 'success', timeout: 2000 });
    }).catch(err => {
      console.log(err);
      actions['globalData/addSnackbar'][0]({ text: 'logout error', showing: true, color: 'error', timeout: 2000 });
    });
  },
  refreshToken (payload) {
    payload.vueAuth.login({ refresh_token: payload.vueAuth.storage.getItem('refresh_token'), grant_type: 'refresh_token', client_id: process.env.VUE_APP_CLIENT_ID, client_secret: process.env.VUE_APP_CLIENT_SECRET }).then((response) => {
      payload.vueAuth.storage.setItem(payload.vueAuth.tokenName, response.data.access_token);
      payload.vueAuth.storage.setItem('refresh_token', response.data.refresh_token);
      if (payload.vueAuth.isAuthenticated()) return payload.vueAuth.isAuthenticated();
      actions['globalData/addSnackbar'][0]({ text: 'token refresh error', showing: true, color: 'error', timeout: 2000 });
      router.push('/sign-in');
    }).catch(err => {
      console.log(err);
      payload.vueAuth.storage.setItem(payload.vueAuth.tokenName, null);
      payload.vueAuth.storage.setItem('refresh_token', null);
      actions['globalData/addSnackbar'][0]({ text: 'token refresh error', showing: true, color: 'error', timeout: 2000 });
      router.push('/sign-in');
    });
  },
  signInRedirect ({ commit }, infos) {
    var vueAuth = this.state.vueAuth;

    if (infos.token) {
      vueAuth.storage.setItem(vueAuth.tokenName, infos.token);
      vueAuth.storage.setItem('refresh_token', infos.refresh_token);
    }
    if (vueAuth.isAuthenticated()) return true;
    if (vueAuth.storage.getItem('refresh_token')) actions.refreshToken({ vueAuth: vueAuth });
    return vueAuth.isAuthenticated() ? true : router.push('/sign-in');
  },
  isAuthenticated ({ commit }) {
    return this.state.vueAuth.isAuthenticated();
  }
};

// mutations
const mutations = {
  isAuthenticated (state, payload) {
    state.isAuthenticated = payload.isAuthenticated;
  },
  setToken (state, payload) {
    state.token = payload.token;
  }
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
};
