<template>
    <v-toolbar class="col-12 pa-0 mb-5">
        <img style="width: 30px" src="augment_logo.png"/>
        <v-spacer></v-spacer>
        <v-btn v-for="item in navbarItems" :key="item.title" v-bind:class="{ active: currentLocation === item.text }" @click="redirectTo(item.text)" icon>
            <v-icon v-bind:class="{ activeicon: currentLocation === item.text }">{{ item.icon }}</v-icon>
        </v-btn>
        <v-btn @click="logout()" icon>
            <v-icon>{{ 'mdi-logout-variant' }}</v-icon>
        </v-btn>
    </v-toolbar>
</template>

<style scoped>
.active {
    background: lightcoral;
}

.activeicon {
    color: white !important;
}
</style>

<script>
import {
  mapState,
  mapActions
} from 'vuex';

import router from '../../router';

export default {
  watch: {},
  name: 'SideBar',

  data () {
    return {
      drawer: true,
      items: null,
      mini: true
    };
  },
  methods: {
    redirectTo: function (name) {
      this.$store.state.userData.currentLocation = name;
      switch (name) {
        case 'dashboard':
          router.push('free-dashboard');
          // router.push(!this.user.organization || !this.user.organization.has_analytics_access ? 'free-dashboard' : 'paying-dashboard')
          break;
        case 'billing':
          router.push('billing');
          break;
        case 'home':
          router.push('/');
          break;
      }
    },
    ...mapActions('authenticate', [
      'logout'
    ])
  },
  computed: {
    ...mapState({
      user: state => state.userData.user,
      currentLocation: state => state.userData.currentLocation,
      navbarItems: state => state.globalData.navbarItems
    })
  }
};
</script>
