<template>
<div class="col-sm-4 col-xs-12">
    <v-card style="border-radius: 10px; height: 150px" elevation="0" class="mx-auto text-center mx-10 mx-auto col-12 text-center" color="white">
        <div style="font-size: 15px; font-weight: 800; height: 40px; margin-top: 12px">
            {{title}}
        </div>
        <div class="text-left ml-1">
            <li style="list-style-type: none; margin-left: 0px; padding-left: 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-for="item in series" :key="item">
                {{ item }}
            </li>
        </div>
        <!-- <apexchart type="bar" height="138px" :options="optionsChart" :series="seriesChart"></apexchart> -->
    </v-card>
</div>
</template>

<script>
export default {
  name: 'Top5Chart',
  data () {
    return {};
  },
  props: ['title', 'optionsChart', 'seriesChart', 'series']
};
</script>
