import axios from 'axios';

const state = () => ({
  viewed_models_free_series: null,
  viewed_models_free_options: null,
  sign_in_free_series: null,
  sign_in_free_options: null,
  model3ds_count: null,
  total_users: null,
  total_folders: null,
  new_org_model3ds: null,
  active_devices_count_uniq: null,
  new_org_places: null,
  most_3_viewed_models: null,
  most_3_activ_users: null,
  most_3_activ_teams: null,
  most_5_viewed_models_chart_series: null,
  most_5_viewed_models_chart_option: null,
  most_5_activ_teams_chart_series: null,
  most_5_activ_teams_chart_option: null,
  most_5_activ_users_chart_series: null,
  most_5_activ_users_chart_option: null,
  donut_chart_options: null,
  donut_chart_series: null
});

// getters
const getters = {};

// actions
const actions = {
  getFirstDashboard ({ commit }, payload) {
    var freeDashboardData = this.state.freeDashboardData;
    axios.get(process.env.VUE_APP_HYPERTEXT + '://webservice.' + process.env.VUE_APP_RAILS_URL + '/v3/keen_queries/get_free_dashboard_data')
      .then(function (response) {
        /// ////// MODEL3Ds COUNT
        freeDashboardData.model3ds_count = response.data['total_model3ds'];
        /// ////// USERS COUNT
        freeDashboardData.total_users = response.data['total_users'];
        /// ////// Shared Folders COUNT
        freeDashboardData.total_folders = response.data['total_folders'];
        /// ////// MODEL3Ds ADDED COUNT
        freeDashboardData.new_org_model3ds = response.data['new_org_model3ds'];
        /// ////// UNIQ ACTIVE DEVICES COUNT
        freeDashboardData.active_devices_count_uniq = response.data['active_devices_count_uniq'].length;
        /// ////// PLACE CREATED
        freeDashboardData.new_org_places = response.data['new_org_places'];

        /// ////// MODEL3DS VIEWS
        freeDashboardData.viewed_models_free_series = [{ name: 'model3d viewed', data: response.data['model3d_loaded'].map(x => x.value) }];
        freeDashboardData.viewed_models_free_options = {
          chart: {
            id: 'viewed_models_free',
            type: 'area',
            sparkline: {
              enabled: false
            },
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          tooltip: {
            enabled: false
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: response.data['model3d_loaded'].map(x => x.date),
            labels: {
              show: false
            }
          },

          plotOptions: {
            tooltip: {
              enabled: false
            }
          },
          colors: ['#21918c']
        };

        /// ////// UNIQ ACTIVE DEVICES
        freeDashboardData.sign_in_free_series = [{ name: 'users connexions', data: response.data['sign_in_count'].map(x => x.value) }];
        freeDashboardData.sign_in_free_options = {
          chart: {
            id: 'uniq_active_free',
            type: 'area',
            sparkline: {
              enabled: false
            },
            toolbar: {
              show: false
            },
            zoom: {
              enabled: false
            }
          },
          tooltip: {
            enabled: false
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          plotOptions: {
            tooltip: {
              enabled: false
            }
          },
          colors: ['#21918c'],
          xaxis: {
            categories: response.data['sign_in_count'].map(x => x.date),
            labels: {
              show: false
            }
          }
        };

        /// ////// DEVICE TYPE REPARTITION
        freeDashboardData.donut_chart_series = response.data['devices_distribution'].map(x => x.count);
        freeDashboardData.donut_chart_options = {
          chart: {
            type: 'donut'
          },
          labels: response.data['devices_distribution'].map(x => x.integration || 'Unknown'),
          dataLabels: {
            enabled: false
          },
          colors: ['#fde725', '#a0da39', '#4ac16d', '#1fa187', '#277f8e', '#365c8d', '#46327e', '#440154'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'center'
              }
            }
          }]
        };

        /// ////// 3 most
        freeDashboardData.most_3_viewed_models = response.data['most_3_viewed_models'].sort((a, b) => b.count - a.count).map(x => x.name);
        freeDashboardData.most_3_activ_teams = response.data['most_3_activ_teams'].sort((a, b) => b.count - a.count).map(x => x.name);
        freeDashboardData.most_3_activ_users = response.data['most_3_activ_users'].sort((a, b) => b.count - a.count).map(x => x.name);

        // ///////// Most 5 Viewed Models
        // freeDashboardData.most_5_viewed_models_chart_option = {
        //     chart: {
        //         type: 'bar',
        //         height: 150,
        //         sparkline: {
        //             enabled: true
        //         }
        //     },
        //     colors: ['#21918c'],
        //     plotOptions: {
        //         bar: {
        //             horizontal: true,
        //         }
        //     },
        //     dataLabels: {
        //         enabled: true,
        //         formatter: function (val, opt) {
        //             return opt.w.globals.labels[opt.dataPointIndex];
        //         }
        //     },
        //     xaxis: {
        //         categories: response.data["most_5_viewed_models"].map(x => x.name)
        //     }
        // }

        // freeDashboardData.most_5_viewed_models_chart_series = [{
        //     data: response.data["most_5_viewed_models"].map(x => x.count)
        // }]

        // ///////// Most 5 Active Teams

        // freeDashboardData.most_5_activ_teams_chart_option = {
        //     chart: {
        //         type: 'bar',
        //         height: 150,
        //         sparkline: {
        //             enabled: true
        //         }
        //     },
        //     colors: ['#21918c'],
        //     plotOptions: {
        //         bar: {
        //             horizontal: true,
        //         }
        //     },
        //     dataLabels: {
        //         enabled: true,
        //         formatter: function (val, opt) {
        //             return opt.w.globals.labels[opt.dataPointIndex];
        //         }
        //     },
        //     xaxis: {
        //         categories: response.data["most_5_activ_teams"].map(x => x.name || 'Unknown')
        //     }
        // }

        // freeDashboardData.most_5_activ_teams_chart_series = [{
        //     data: response.data["most_5_activ_teams"].map(x => x.count)

        // }]

        // ///////// most_5_activ_users
        // freeDashboardData.most_5_activ_users_chart_option = {
        //     chart: {
        //         type: 'bar',
        //         height: 150,
        //         sparkline: {
        //             enabled: true
        //         }
        //     },
        //     colors: ['#21918c'],
        //     plotOptions: {
        //         bar: {
        //             borderRadius: 4,
        //             horizontal: true,
        //             barHeight: '90%'
        //         }
        //     },
        //     dataLabels: {
        //         enabled: true,
        //         formatter: function (val, opt) {
        //             return opt.w.globals.labels[opt.dataPointIndex];
        //         }
        //     },
        //     xaxis: {
        //         categories: response.data["most_5_activ_users"].map(x => x.name || 'Unknown')
        //     }
        // }

        // freeDashboardData.most_5_activ_users_chart_series = [{
        //     data: response.data["most_5_activ_users"].map(x => x.count)
        // }]
      });
  }
};

// mutations
const mutations = {

};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
};
