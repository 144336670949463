import { router } from '../../router';
import axios from 'axios';

const state = () => ({
  snackbars: [],
  navbarItems: []
});

// getters
const getters = {
};

// actions
const actions = {
  addSnackbar ({ commit }, snackbar) {
    this.state.globalData.snackbars.push(snackbar);
  },
  addDashboard ({ commit }, snackbar) {
    this.state.globalData.navbarItems.push({
      icon: 'mdi-view-dashboard-outline',
      text: 'dashboard',
      link: 'user.master_user'
    });
  },
  addBilling ({ commit }, snackbar) {
    this.state.globalData.navbarItems.push({
      icon: 'mdi-currency-usd',
      text: 'billing'
    });
  }
};

// mutations
const mutations = {
  setSnackbars (state, snackbar) {
    state.snackbars.push(snackbar);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
