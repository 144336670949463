
<template>
<div style="background: rgb(245, 245, 245); margin: 0px !important;" class="pa-sm-10 pa-xs-0 pt-xs-10 col-xs-12  col-sm-11 mx-auto">
    <h2 class="ml-md-15" style="font-weight: 300;">Dashboard Analytics <v-chip small color="primary" style="font-weight: 700; font-size: 10px">FREE</v-chip> <v-chip small color="#21918c" style="font-weight: 700; color: white; font-size: 10px">last 72h</v-chip></h2>

    <div v-if="total_users === null" class="col-12 text-center mt-10 pt-10">
        <v-progress-circular
            style="margin-top: 25vh"
            :size="50"
            :width="3"
            indeterminate
            color="#21918c"
        ></v-progress-circular>
    </div>

    <div v-if="total_users">
        <v-row style="padding-left: 0px !important; padding-right: 0px !important;" class="mx-auto col-12 px-10 mt-5">
            <text-card :card-text="'TOTAL MODELS: '+model3ds_count"></text-card>
            <text-card :card-text="'TOTAL USERS: '+total_users"></text-card>
            <text-card :card-text="'TOTAL SHARED FOLDERS: '+total_folders"></text-card>
        </v-row>

        <v-row style="padding-left: 0px !important; padding-right: 0px !important;" class="mx-auto col-12 px-10 mt-0">
            <text-card :card-text="'+ '+new_org_model3ds+' MODELS ADDED'"></text-card>
            <text-card :card-text="'+ '+new_org_places+' PLACES CREATED'"></text-card>
            <text-card :card-text="active_devices_count_uniq+' ACTIVE DEVICE'"></text-card>
        </v-row>

        <v-row style="padding-left: 0px !important; padding-right: 0px !important;" class="mx-auto col-12 px-10 mt-0">
            <line-chart-card title="MODEL VIEWS" :options-chart="viewed_models_free_options" :series-chart="viewed_models_free_series"></line-chart-card>
            <line-chart-card title="ACTIVE DEVICES" :options-chart="sign_in_free_options" :series-chart="sign_in_free_series"></line-chart-card>
            <donut-chart-card title="DEVICE TYPE" :options-chart="donut_chart_options" :series-chart="donut_chart_series"></donut-chart-card>
        </v-row>

        <!-- <v-row class="mx-auto col-12 px-10 mt-5">
            <top-5-chart title="MOST VIEWED MODELS" :options-chart="most_5_viewed_models_chart_option" :series-chart="most_5_viewed_models_chart_series"></top-5-chart>
            <top-5-chart title="MOST ACTIVE TEAMS" :options-chart="most_5_activ_teams_chart_option" :series-chart="most_5_activ_teams_chart_series"></top-5-chart>
            <top-5-chart title="MOST ACTIVE USERS" :options-chart="most_5_activ_users_chart_option" :series-chart="most_5_activ_users_chart_series"></top-5-chart>
        </v-row> -->

         <v-row style="padding-left: 0px !important; padding-right: 0px !important;" class="mx-auto col-12 px-10 mt-0">
            <top-5-chart title="MOST VIEWED MODELS" :series="most_3_viewed_models"></top-5-chart>
            <top-5-chart title="MOST ACTIVE TEAMS" :series="most_3_activ_teams"></top-5-chart>
            <top-5-chart title="MOST ACTIVE USERS" :series="most_3_activ_users"></top-5-chart>
        </v-row>
    </div>
</div>
</template>

<style>
body {
    font-family: 'Roboto';
}

.v-sheet--offset {
    top: -24px;
    position: relative;
}

.apexcharts-svg {
    border-radius: 10px !important;
}

.apexcharts-tooltip {
    display: none !important;
}
</style>>

<script>
import { mapActions, mapState } from 'vuex';
import TextCard from './free_dashboard/TextCard.vue';
import LineChartCard from './free_dashboard/LineChartCard.vue';
import DonutChartCard from './free_dashboard/DonutChartCard.vue';
import Top5Chart from './free_dashboard/Top5Chart.vue';
import router from '../../router';

export default {
  name: 'FreeDashBoard',
  components: {
    TextCard,
    LineChartCard,
    DonutChartCard,
    Top5Chart
  },
  data () {
    return {
    };
  },
  methods: {
    ...mapActions('authenticate', [
      'signInRedirect'
    ]),
    ...mapActions('userData', [
      'getUserData'
    ]),
    ...mapActions('freeDashboardData', [
      'getFirstDashboard'
    ])
  },
  computed: {
    ...mapState({
      viewed_models_free_series: state => state.freeDashboardData.viewed_models_free_series,
      viewed_models_free_options: state => state.freeDashboardData.viewed_models_free_options,
      sign_in_free_series: state => state.freeDashboardData.sign_in_free_series,
      sign_in_free_options: state => state.freeDashboardData.sign_in_free_options,
      model3ds_count: state => state.freeDashboardData.model3ds_count,
      total_users: state => state.freeDashboardData.total_users,
      total_folders: state => state.freeDashboardData.total_folders,
      new_org_places: state => state.freeDashboardData.new_org_places,
      new_org_model3ds: state => state.freeDashboardData.new_org_model3ds,
      active_devices_count_uniq: state => state.freeDashboardData.active_devices_count_uniq,
      // most_5_viewed_models_chart_series: state => state.freeDashboardData.most_5_viewed_models_chart_series,
      // most_5_viewed_models_chart_option: state => state.freeDashboardData.most_5_viewed_models_chart_option,
      // most_5_activ_teams_chart_series: state => state.freeDashboardData.most_5_activ_teams_chart_series,
      // most_5_activ_teams_chart_option: state => state.freeDashboardData.most_5_activ_teams_chart_option,
      // most_5_activ_users_chart_series: state => state.freeDashboardData.most_5_activ_users_chart_series,
      // most_5_activ_users_chart_option: state => state.freeDashboardData.most_5_activ_users_chart_option,
      donut_chart_series: state => state.freeDashboardData.donut_chart_series,
      donut_chart_options: state => state.freeDashboardData.donut_chart_options,
      user: state => state.userData.user,
      most_3_viewed_models: state => state.freeDashboardData.most_3_viewed_models,
      most_3_activ_users: state => state.freeDashboardData.most_3_activ_users,
      most_3_activ_teams: state => state.freeDashboardData.most_3_activ_teams
    })
  },
  async mounted () {
    this.signInRedirect({token: this.$route.query.token, refresh_token: this.$route.query.refresh_token});
    if (!this.user) {
      await this.getUserData({
        token: this.$route.query.token,
        refresh_token: this.$route.query.refresh_token
      });
    }
    if (!this.user.master_user) router.push('/');
    this.getFirstDashboard();
  }
};
</script>
