import router from '../../router';
import axios from 'axios';

const state = () => ({
  user: null,
  keen_key: null,
  strip_upcoming_invoice: null,
  billing_data_count: null,
  billing_data_series: null,
  billing_data_options: null,
  billing_data_area_count: null,
  billing_data_area_series: null,
  billing_data_area_options: null,
  billing_invoice_variwide_data: [],
  billing_invoice_variwide_options: null,
  multipleSeries: null,
  multipleChartOptions: null,
  multiStart: null,
  multiEnd: null,
  allReadyPaid: null,
  prevision: null,
  currentMonthAdd: 0,
  currentLocation: 'dashboard',
  subscriptionAcademic: null,
  donut_chart_options: null,
  donut_chart_series: null,
  invoiceList: null,
  invoiceDropdown: null,
  currentPdfLink: null,
  tableCredit: null,
  tableCurrentPaidProvision: null,
  tableCurrentPaidCharges: null,
  tableNextProvisions: null,
  tableAppliedBalance: null,
  tableAmountDue: null,
  currency: null
});

// getters
const getters = {};

// actions
const actions = {
  getUserData ({ commit }, payload) {
    return new Promise(resolve => {
      var vueAuth = this.state.vueAuth;
      var navbarItems = this.state.globalData.navbarItems;
      var mutations = this._mutations;
      var actions = this._actions;
      if (vueAuth.isAuthenticated() === true) {
        axios.get(process.env.VUE_APP_HYPERTEXT + '://webservice.' + process.env.VUE_APP_RAILS_URL + '/v3/users/infos')
          .then(function (response) {
            mutations['userData/setUser'][0](response.data);
            navbarItems = [];
            if (response.data.master_user) {
              actions['globalData/addDashboard'][0]();
              axios.get(process.env.VUE_APP_HYPERTEXT + '://webservice.' + process.env.VUE_APP_RAILS_URL + '/v3/keen_api_keys/get_key')
                .then(function (response) {
                  mutations['userData/setKeenKey'][0](response.data);
                });
            }
            if (response.data.has_enterprise_plan) {
              actions['globalData/addBilling'][0]();
            }
            resolve(true);
          });
      } else {
        this._actions['authenticate/signInRedirect'][0](payload);
        resolve(true);
      }
    });
  },
  setBillingData ({ commit }, invoice) {
    var userData = this.state.userData;
    let data = invoice.lines.data.sort((a, b) => a.period.start - b.period.start);
    userData.currentMonthAdd = 0;
    function currentMonthAddAccumulateur (element, index) {
      if (index < data.length - 1) {
        userData.currentMonthAdd += element.amount;
      }
    }

    data.forEach(currentMonthAddAccumulateur);
    userData.allReadyPaid = data[0];
    userData.prevision = data[data.length - 1];
    userData.currentPdfLink = invoice.invoice_pdf;

    userData.billing_data_count = (invoice.amount_due * 0.01).toFixed(2);
    userData.billing_data_series = [{ name: 'Upcoming Invoice', data: invoice.lines.data.map(x => (x['amount'] * 0.01)) }];

    // Set paid provision
    userData.billing_data_series[0].data.unshift(invoice.lines.data[0].quantity * invoice.lines.data[0].price.unit_amount * 0.01).toFixed(2);
    userData.tableCurrentPaidProvision = userData.billing_data_series[0].data[0].toFixed(2);
    userData.tableCurrentPaidCharges = userData.billing_data_series[0].data.slice(0, -1).reduce((previousValue, currentValue) => previousValue + currentValue).toFixed(2);
    userData.tableAppliedBalance = ((invoice.subtotal > 0) ? 0 : -invoice.subtotal * 0.01).toFixed(2); ;
    userData.tableCredit = (invoice.starting_balance * -0.01).toFixed(2);
    userData.tableNextProvisions = userData.billing_data_series[0].data[invoice.lines.data.length].toFixed(2);
    userData.currency = invoice.currency;

    // Set column chart
    let map = [];

    if (userData.invoiceDropdown.slice(-1)[0].invoice.period_start === invoice.period_start && userData.invoiceDropdown.slice(-1)[0].invoice.period_end === invoice.period_end && userData.billing_data_series[0].data.length === 2) {
      invoice.lines.data.forEach(function (element, index) {
        map.push('Next month provision: ' + element.quantity + ' devices');
      });
      userData.billing_data_series[0].data.pop();
    } else if (userData.billing_data_series[0].data.length === 2) {
      invoice.lines.data.forEach(function (element, index) {
        map.push('Paid provision: ' + element.quantity + ' devices');
        map.push('Next month provision: ' + element.quantity + ' devices');
      });
    } else {
      invoice.lines.data.forEach(function (element, index) {
        if (index === 0) {
          map.push('Paid provision: ' + element.quantity + ' devices');
          map.push('Unused paid provision: ' + element.quantity + ' devices');
        } else if (index === invoice.lines.data.length - 1) {
          map.push('Next month provision: ' + element.quantity + ' devices');
        } else {
          if (element.amount >= 0) {
            map.push('Provisional charges: ' + element.quantity + ' devices');
          } else {
            map.push('Unused provisional charges: ' + element.quantity + ' devices');
          }
        }
      });
    }

    invoice.lines.data.map(x => x['description']);
    userData.billing_data_options = {
      chart: {
        id: 'upcoming_invoice_bar',
        type: 'bar'
      },
      title: {
        text: userData.billing_data_count + ' € Upcoming Bill',
        align: 'center',
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        }
      },
      tooltip: {
        enabled: true
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#000']
        },
        textAnchor: 'middle',
        offsetX: 0
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [{
              from: -1000000,
              to: 0,
              color: '#fde725'
            }, {
              from: 0,
              to: 10000000,
              color: '#21918c'
            }]
          },
          borderRadius: 10,
          dataLabels: {
            hideOverflowingLabels: true,
            position: 'bottom' // top, center, bottom
          },
          columnWidth: '80%'
        }
      },
      xaxis: {
        categories: map,
        labels: {
          rotate: -45,
          minHeight: undefined,
          maxHeight: 150,
          style: {
            colors: [],
            fontSize: '9px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-xaxis-label'
          }
        }
      },
      colors: ['#2c115f']
    };

    /// /////// Height & Width
    var height = 200;
    var width = 1200;

    /// /////// Variwide Invoice
    let bigest_amount = 0;
    let bigest_quantity = 0;
    let created = invoice.period_start;
    let main_end = invoice.period_end;
    let widthGraphic = main_end - created;
    let widthGraphicRatio = width / widthGraphic;
    let heightGraphicRatio;
    let transform = 0;
    let xGridRatio = Math.round(widthGraphic / width * 100) / 100;
    const options = { dateStyle: 'short' };
    let end_date = new Date(main_end * 1000).toLocaleString('en', options);

    /// //////// Multiple chart datas
    userData.multiStart = new Date(created * 1000);
    userData.multiEnd = new Date(main_end * 1000);
    var multiTimeLenght = Math.round((userData.multiEnd - userData.multiStart) / (1000 * 60 * 60 * 24));

    userData.multipleSeries = [
      {
        name: 'Cost',
        type: 'area',
        data: []
      }, {
        name: 'Subscription',
        type: 'line',
        data: []
      }, {
        name: 'Devices Count',
        type: 'line',
        data: []
      }];

    userData.multipleChartOptions = {
      chart: {
        height: 350,
        type: 'line'
      },
      title: {
        text: '[' + userData.multiStart.toISOString().substring(0, 10) + ' - ' + userData.multiEnd.toISOString().substring(0, 10) + '] Subscription Usage',
        align: 'center',
        margin: 30,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263238'
        }
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'solid',
        opacity: [0.35, 1]
      },
      labels: [],
      markers: {
        size: 0
      },
      colors: ['#21918c', '#fde725', '#440154'],
      yaxis: [
        {
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#21918c'
          },
          labels: {
            style: {
              colors: '#21918c'
            }
          },
          title: {
            text: 'Price in €',
            style: {
              color: '#21918c'
            }
          },
          tooltip: {
            enabled: true
          }
        },
        {
          opposite: true,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#fde725'
          },
          labels: {
            style: {
              colors: '#fde725'
            }
          },
          title: {
            text: 'Price in €',
            style: {
              color: '#fde725'
            }
          }
        },
        {
          opposite: true,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#440154'
          },
          labels: {
            style: {
              colors: '#440154'
            }
          },
          title: {
            text: 'Device count',
            style: {
              color: '#440154'
            }
          }
        }
      ],
      dataLabels: {
        enabled: false
        // enabledOnSeries: [1]
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y, z) {
            if (typeof y !== 'undefined') {
              if (z.seriesIndex === 0) {
                return y.toFixed(2) + ' € monthly || ' + (y.toFixed(2) / multiTimeLenght).toFixed(2) + ' € daily';
              } else if (z.seriesIndex === 1) {
                return y.toFixed(2) + ' € per device';
              } else {
                return y.toFixed(2) + ' device(s)';
              }
            }
            return y;
          }
        }
      }
    };

    var loop = new Date(userData.multiStart);
    var datetime;
    var i = 0;

    while (loop <= userData.multiEnd) {
      datetime = loop.getTime() / 1000;

      while (!!data[i] && datetime > data[i].period.start && (i < data.length - 1)) {
        i++;
      }

      // while (!!data[i] && (i < data.length - 1) && (data[i + 1].period.start === data[i].period.start)) {
      //     i++;
      // }

      userData.multipleChartOptions.labels.push(loop.toISOString().substring(0, 10));
      userData.multipleSeries[1].data.push(data[i].plan.amount / 100);
      userData.multipleSeries[2].data.push(data[i].quantity);
      userData.multipleSeries[0].data.push((data[i].quantity * data[i].plan.amount) / 100);

      var newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }

    /// ////////

    for (i = data.length - 1; i >= 0; --i) {
      if (data[i].amount < 0 && i > 1) {
        data[i].period.start = data[i - 1].period.start;
        data.splice(i - 1, 1);
      }
    }

    data.forEach(function (element, index) {
      let localAmount;
      let start;
      let end;
      let width;
      let x_position;
      let date;

      localAmount = Math.round(((element.amount < 0) ? (element.plan.amount + element.amount) * 0.01 : element.amount * 0.01) * 100) / 100;
      if (element.plan.amount > bigest_amount) bigest_amount = element.plan.amount;
      if (element.quantity > bigest_quantity) bigest_quantity = element.quantity;
      if (index === 0 && data.length > 1) {
        start = created;
      } else {
        start = element.period.start;
      }

      if (index === data.length - 1) {
        end = element.period.end;
      } else {
        end = data[index + 1].period.start;
      }

      width = Math.round((end - start) * widthGraphicRatio * 10000) / 10000;
      x_position = Math.round((start - created) * widthGraphicRatio * 10000) / 10000;
      date = new Date(start * 1000).toISOString().substring(0, 10);
      // if (amount < )
      userData.billing_invoice_variwide_data.push({ amount: localAmount, description: element.description, price_plan: element.plan.amount, start: start, end: end, width: width, transform: 'translate(' + transform + ',0)', x_position: x_position, date: date, quantity: element.quantity, billing_scheme: element.plan.billing_scheme });
      transform += width;
    });

    heightGraphicRatio = height / bigest_amount;
    // console.log(type)
    // if (type == "CompanyContactInformation") {
    //     heightGraphicRatio = heightGraphicRatio / bigest_quantity;
    // }

    userData.billing_invoice_variwide_options = { items_count: data.length, created: created, widthGraphic: widthGraphic, end: main_end, heightGraphicRatio: heightGraphicRatio, widthGraphicRatio: widthGraphicRatio, xGridRatio: xGridRatio, end_date: end_date };

    userData.billing_invoice_variwide_data.forEach(function (element, index) {
      element.y = height - (element.price_plan * userData.billing_invoice_variwide_options.heightGraphicRatio);
      // if (type === "CompanyContactInformation") {
      //     element.y = height - (element.price_plan * element.quantity * userData.billing_invoice_variwide_options.heightGraphicRatio)
      // } else {
      element.y = height - (element.price_plan * userData.billing_invoice_variwide_options.heightGraphicRatio);
      // }
      // if (type === "CompanyContactInformation") {
      //     element.calcHeight = element.price_plan * element.quantity * userData.billing_invoice_variwide_options.heightGraphicRatio
      // } else {
      element.calcHeight = element.price_plan * userData.billing_invoice_variwide_options.heightGraphicRatio;
      // }
    });
  },
  getBillingData ({ commit }, payload) {
    var vueAuth = this.state.vueAuth;
    var mutations = this._mutations;
    var userData = this.state.userData;
    if (vueAuth.isAuthenticated() === true) {
      axios.get(process.env.VUE_APP_HYPERTEXT + '://webservice.' + process.env.VUE_APP_RAILS_URL + '/v3/stripe_api/get_billing_data')
        .then(response => {
          let type = response.data.subcription_type;
          userData.subscriptionAcademic = type === 'AcademicContactInformation';
          userData.invoiceList = response.data.stripe_upcoming_invoice;
          userData.invoiceDropdown = response.data.stripe_upcoming_invoice.map((itm, index) =>
            ({
              date: index === response.data.stripe_upcoming_invoice.length - 1 ? '[' + new Date(itm.period_start * 1000).toISOString().split('T')[0] + ']' : '[' + new Date(itm.period_start * 1000).toISOString().split('T')[0] + ' - ' + new Date(itm.period_end * 1000).toISOString().split('T')[0] + ']',
              invoice: itm
            }));
          userData.selectedInvoice = userData.invoiceDropdown[0];
          this._actions['userData/setBillingData'][0](response.data.stripe_upcoming_invoice[0]);
        });
    } else {
      this._actions['authenticate/signInRedirect'][0](payload);
    }
  }
};

// mutations
const mutations = {
  setUser (state, user) {
    state.user = user;
  },
  setKeenKey (state, keen_key) {
    state.keen_key = keen_key;
  },
  setStripeUpcomingInvoice (state, strip_upcoming_invoice) {
    state.strip_upcoming_invoice = strip_upcoming_invoice;
  }
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
};
